<template>
  <v-dialog v-model="showDialog" persistent :max-width="maxWidth">
    <v-card>
      <v-row class="mr-0 pa-3">
        <v-btn
          rounded
          class="font-weight-black ml-auto"
          color="back"
          width="4vw"
          max-width="170px"
          style="font-size:13px !important"
          dark
          @click="onClickNegativeButton"
        >
          {{ negativeButtonTitle }}
        </v-btn>
      </v-row>
      <v-card-title
        v-if="title !== null"
        class="headline font-weight-bold py-0"
      >
        {{ title }}
      </v-card-title>
      <v-card-text
        v-if="text !== null"
        class="font-weight-black text-left pt-5"
      >
        <p>
          {{ text }}
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    maxWidth: Number,
    showDialog: Boolean,
    title: String,
    text: String,
    negativeButtonTitle: String,
    onClickNegativeButton: Function,
  },
};
</script>

