var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": _vm.maxWidth },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-row",
            { staticClass: "mr-0 pa-3" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "font-weight-black ml-auto",
                  staticStyle: { "font-size": "13px !important" },
                  attrs: {
                    rounded: "",
                    color: "back",
                    width: "4vw",
                    "max-width": "170px",
                    dark: "",
                  },
                  on: { click: _vm.onClickNegativeButton },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.negativeButtonTitle) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm.title !== null
            ? _c(
                "v-card-title",
                { staticClass: "headline font-weight-bold py-0" },
                [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
              )
            : _vm._e(),
          _vm.text !== null
            ? _c(
                "v-card-text",
                { staticClass: "font-weight-black text-left pt-5" },
                [
                  _c("p", [
                    _vm._v("\n        " + _vm._s(_vm.text) + "\n      "),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }